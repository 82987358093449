import React from 'react';
import Section from '../../components/section';
import Layout from '../../components/layout/layout';

import Button from '../../components/button';
import logo from '../../images/hexomatic-logo.svg';
import dash from '../../images/affiliate/hexomatic-dash.png';
import impactLogo from '../../images/affiliate/impact-logo.svg';
import portfolioIcon from '../../images/affiliate/portfolio-icon.svg';
import agencyIcon from '../../images/affiliate/agency-icon.svg';
import earningPotential from '../../images/affiliate/earning-potential.png';
import './styles.scss';

const Affiliate: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <div className="affiliate-page">
        <Section className="seo_home_area">
          <div className="container_home">
            <div className="row_home">
              <div className="col-lg-6 d-flex align-center" style={{verticalAlign: 'middle'}}>
                <div>
                  <h1 className="h4-title f_size_24 t_color3">
                    Become a Hexomatic affiliate partner and earn 20% commission on every sale and renewal.
                  </h1>
                  <p className="desc_1">
                    Hexomatic has partnered with Impact to power their affiliate program for world-class tracking and
                    convenient payouts.
                  </p>
                  <Button
                    primary
                    className="trial_btn"
                    onClick={() => window.open('https://app.impact.com/campaign-promo-signup/Hexact.brand')}
                  >
                    Sign up now
                  </Button>
                  <div className="logos">
                    <img src={logo} className="hexomatic_logo" alt="Hexomatic.com" />
                    <div className="divider"></div>
                    <img src={impactLogo} className="affiliate_logo" alt="impact.com" />
                  </div>
                  <h4 className="h4-title f_size_24 t_color3">How much can you earn?</h4>
                  <p className="desc_1">
                    Our affiliate program is the perfect way to generate recurring revenue by introducing Hexomatic to
                    your audience and local businesses.
                  </p>
                  <p className="desc_1">
                    Hexomatic has plans for all business sizes from small businesses, advanced professionals and power
                    users &amp; teams.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-center img header-img-block">
                <img
                  src={dash}
                  alt="AI sidekick to protect and grow eCommerce business"
                  style={{maxWidth: '100%', marginRight: '-20px'}}
                />
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className="col-12 d-block mb_20" style={{maxWidth: '1200px', width: '100%'}}>
            <div className="row">
              <div className="col-lg-6 selling-item">
                <div className="img-circle">
                  <img src={portfolioIcon} alt={'portfolio'} />
                </div>
                <h5>Selling to Power users and large teams</h5>
                <p>
                  If you sold just 10 Gold annual plans this month, you could earn a sweet
                  <span className="mark">$2000</span> and every year collect up to <span className="mark">$2000*</span>{' '}
                  in commissions as long as they keep subscribing.
                </p>
                <p>
                  Plus you can earn <span className="mark"> 20% </span>recurring commissions on premium credit
                  purchases.
                </p>
                <p>*Based on customers renewing their Gold plans on a yearly basis.</p>
              </div>
              <div className="col-lg-6 selling-item">
                <div className="img-circle ">
                  <img src={agencyIcon} alt={'agency'} />
                </div>
                <h5>Selling to SMEs</h5>
                <p>
                  If you sold just 10 Silver annual plans this month, you could earn a sweet
                  <span className="mark">$1000</span> and every year collect up to <span className="mark">$1000**</span>{' '}
                  in commissions as long as they keep subscribing.
                </p>
                <p>
                  Plus you can earn <span className="mark"> 20% </span>recurring commissions on premium credit
                  purchases.
                </p>
                <p>**Based on customers renewing their Silver plans on a yearly basis.</p>
              </div>
            </div>
            <div className="row row-reverse">
              <div className="col-lg-6 d-flex">
                <div className="m-auto text-center text-lg-left">
                  <h5 className="text-center text-lg-left">Tap into unlimited earning potential</h5>
                  <p className="text-center text-lg-left">
                    Earn commissions on all purchases made by new clients you refer across Hexomatic, Hexowatch,
                    Hexometer, Hexospark, and premium credit subscriptions.
                  </p>
                  <Button
                    primary
                    className="trial_btn"
                    onClick={() => window.open('https://app.impact.com/campaign-promo-signup/Hexact.brand')}
                  >
                    Sign up now
                  </Button>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <img
                  src={earningPotential}
                  style={{maxWidth: '450px', width: '100%', margin: 'auto'}}
                  alt="Earning Potential"
                />
              </div>
            </div>
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default Affiliate;
